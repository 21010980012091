<template>
  <div v-if="showLoader">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <img
              src="media/svg/transparentLoader.svg"
              class="transparentLoader"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style>
.transparentLoader {
  position: absolute;
  z-index: 999999 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>

<script>
import { bus } from "@/main";

export default {
  name: "LoaderTransparent",
  data() {
    return {
      showLoader: false
    };
  },
  mounted() {
    bus.$on("showTransparentLoader", this.onShowTransparentLoader);
    bus.$on("hideTransparentLoader", this.onHideTransparentLoader);
  },
  destroyed() {
    bus.$off("showTransparentLoader", this.onShowTransparentLoader);
    bus.$off("hideTransparentLoader", this.onHideTransparentLoader);
  },
  methods: {
    onShowTransparentLoader() {
      this.showLoader = true;
    },
    onHideTransparentLoader() {
      this.showLoader = false;
    }
  }
};
</script>
