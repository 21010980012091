<template>
  <!-- begin:: Footer -->
  <div
    id="kt_footer"
    class="yedi-footer footer bg-white border-top py-4 d-flex flex-lg-column zindex-10"
  >
    <div
      class="d-flex align-items-center justify-content-between"
      :class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="text-dark">
        <span class="mr-2"> {{ year }}&nbsp;&copy;</span>
        <!--        <a
          href="https://yedi.de"
          target="_blank"
          class="text-white text-hover-primary mr-2"
        >
          YEDI
        </a>-->
        <span
          class="text-white text-hover-primary mr-2"
          @click="copyText(uuid)"
        >
          {{ uuid }}
        </span>

        <span class="">{{ apiVersion }}</span>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";
// import Auth from "@/components/General/Auth/auth";

export default {
  name: "KTFooter",
  data() {
    return {
      year: "",
      apiVersion: "",
      uuid: ""
    };
  },
  computed: {
    ...mapGetters(["layoutConfig", "selectedClient"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  },
  mounted() {
    this.uuid = this.selectedClient?.uuid ?? null;
    this.getYear();
    this.getApiVersion();
  },
  methods: {
    copyText(text) {
      this.$copyText(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    },
    getYear() {
      let moment = require("moment");
      this.year = moment().year();
    },
    getApiVersion() {
      /*
      Auth.getApiVersion()
        .then(response => {
          this.apiVersion = response.data.data.api;
        })
        .catch(error => {
          this.$toast.fire({
            icon: "error",
            title: error.response.data.message
          });
        });
       */
    }
  }
};
</script>

<style lang="scss">
.zindex-10 {
  z-index: 10;
}
</style>
