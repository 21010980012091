<template>
  <div
    v-if="isAuthenticated"
    class="d-flex flex-column flex-root"
    style="height: 100vh"
  >
    <Loader :logo="loaderLogo"></Loader>
    <LoaderTransparent></LoaderTransparent>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <div
        id="kt_wrapper"
        class="d-flex flex-column flex-row-fluid wrapper p-0"
      >
        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid p-0 overflow-hidden"
          style="background-color: #f6f6f6"
        >
          <!-- begin:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div class="container-fluid p-0">
              <transition name="fade-in-up">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
  </div>
</template>

<script>
import KTFooter from "@/components/SYS/layout/footer/Footer.vue";
import Loader from "@/components/SYS/content/Loader.vue";
import LoaderTransparent from "@/components/SYS/content/LoaderTransparent";

import Auth from "@/components/General/Auth/auth";
import HtmlClass from "@/core/services/htmlclass.service";
import { mapGetters } from "vuex";

export default {
  name: "Layout",
  components: {
    KTFooter,
    Loader,
    LoaderTransparent
  },
  data() {
    return {
      isAuthenticated: false,
      showSubmenu: false
    };
  },
  beforeMount() {
    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    this.checkAuth();
  },
  methods: {
    // check if current user is authenticated
    checkAuth() {
      let verified = Auth.verify();
      if (verified.success) {
        this.isAuthenticated = true;
      }
      if (!verified.success) {
        this.$router.push({ name: "login" });
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    }
  }
};
</script>
